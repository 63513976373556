import React from 'react';
import ReactDOM from 'react-dom'
import Fade from '@material-ui/core/Fade';

import bg01 from '../images/bg01.jpg';
import bg02 from '../images/bg02.jpg';
import bg03 from '../images/bg03.jpg';
import bg04 from '../images/bg04.jpg';

import bg01sm from '../images/sm/bg01.jpg';
import bg02sm from '../images/sm/bg02.jpg';
import bg03sm from '../images/sm/bg03.jpg';
import bg04sm from '../images/sm/bg04.jpg';

const bgImgs = [
    {
        src: bg01,
        src_sm: bg01sm,
        alt: "home bg 01"
    },
    {
        src: bg02,
        src_sm: bg02sm,
        alt: "home bg 02"
    },
    {
        src: bg03,
        src_sm: bg03sm,
        alt: "home bg 03"
    },
    {
        src: bg04,
        src_sm: bg04sm,
        alt: "home bg 04"
    }
]

class PhotoPanel extends React.Component {
    constructor(props) {
        super(props);
        var lastIndex = bgImgs.length - 1;
        this.state = {
            imgProps: {
                index: 4,
                src: bgImgs[lastIndex]['src'], 
                src_sm: bgImgs[lastIndex]['src_sm'],
                alt: bgImgs[lastIndex]['alt'],
                fadeIn: Boolean(1),
                timeout: 2000
            }
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.nextImage(),
            6000
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    nextImage() {
        var idx = this.state.imgProps.index;
        if (idx > 3) {
            idx = 0;
        }

        this.setState({
            imgProps: {
                fadeIn: Boolean(0),
                timeout: 0
            }
        })

        this.setState({
            imgProps: {
                index: idx + 1,
                fadeIn: Boolean(1),
                timeout: 3000,
                src: bgImgs[idx].src, 
                src_sm: bgImgs[idx].src_sm,
                alt: bgImgs[idx].alt
            }
        })
    }

    render() {
        var fadeIn = this.state.imgProps.fadeIn
        var timeout = this.state.imgProps.timeout
        var src = this.state.imgProps.src
        var src_sm = this.state.imgProps.src_sm
        var alt = this.state.imgProps.alt

        return(
            <div>
            <Fade in={fadeIn} timeout={timeout}>
            <picture>
                <source srcSet={src} media="(min-width: 800px)"/>
                <img src={src_sm} alt={alt}/>
            </picture>
            </Fade>
            </div>
        )
    }
}

ReactDOM.render(
    <PhotoPanel />,
    document.getElementById('root')
)

export default PhotoPanel