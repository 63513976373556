import React from 'react'
import Container from '@material-ui/core/Container'
import Typograrphy from '@material-ui/core/Typography'
//import playfair from './HomePanel/fonts/PlayfairDisplay/PlayfairDisplay-BlackItalic.ttf'

import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const HomePanel = () => {
    return(
        <Container width="100%">
            <ThemeProvider theme={theme}>
                <Typograrphy 
                    variant="h1" component="h1" 
                    classes={{root: "styled"}} 
                    align="center" noWrap={(Boolean(1))}>We do web
                </Typograrphy>
            </ThemeProvider>
        </Container>
    )
}
export default HomePanel;