import React from 'react'
import Box from '@material-ui/core/Box'

import logo from '../images/icons/evomax.png'
import logo_sm from '../images/icons/evomax_sm.png'
import logo_xs from '../images/icons/evomax_xs.png'

//import Typography from '@material-ui/core/Typography'

const NavBar = () => {
    return(
        <Box>
            <picture>
                <source srcSet={logo} media="(min-width: 860px)"/>
                <source srcSet={logo_sm} media="(min-width: 500px)"/>
                <img src={logo_xs} alt="EvoMax logo" />
            </picture>
        </Box>
    )
}
export default NavBar;