import React from 'react';
import Grid from '@material-ui/core/Grid'
import NavBar from './NavBar'
import HomePanel from './HomePanel'
import PhotoPanel from './PhotoPanel'

const TwoPanelLayout = () => {
    return(
        <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={6} style={{ padding: 40 }} width="1/2">
                <NavBar />
                <HomePanel />
            </Grid>
            <Grid item xs={12} sm={12} md={6} width="1/2" align="center">
                <PhotoPanel />
            </Grid>
        </Grid>
    )
}

export default TwoPanelLayout;